window._ = require('lodash');
window.Popper = require('popper.js').default;
window.$ = window.jQuery = require('jquery');
window.EXIF = require('exif-js');

import 'lity';
import 'slick-carousel';

var LazyLoad = require('lazyload');
var jQueryBridget = require('jquery-bridget');
var InfiniteScroll = require('infinite-scroll');

window.onload = () =>{
  jQueryBridget( 'infiniteScroll', InfiniteScroll, $ );

  let images = document.querySelectorAll(".lazy");
  new LazyLoad(images);
};
